import React, { useRef } from 'react';

import jet from "@randajan/jetpack";

import Core, { Link, Ico } from "@randajan/react-app-core";

import { Menu, Trigger } from "@randajan/react-form";

import "./MainMenu.scss";

function MainMenu({flat, position, subposition}) {
  const lang = Core.use("lang");
  const [contact, setContact] = Core.useKey("page.search.contact");

  const ref = useRef();
  const blur = _=>ref.current.blur();
  const link = to=><Link key={to} to={to} onClick={blur}>{lang.spell(["map", to, "to"])}</Link>

  const propsSelf = {
    ref, flat, position,
    className:"MainMenu",
    trigger:<Ico src="menu"/>,
    transition:{enter:200, exit:600}
  }

  return (
    <Menu {...propsSelf}>
      {[link("/"), link("/services"), link("/about"), link("/price/list")]}
      <a className={jet.str.to([contact?"active":""], " ")} onClick={_=>{blur(); setContact(contact ? undefined : 1)}}>
        {lang.spell("map.contact.to")}
      </a>
    </Menu>
  );
}
export default MainMenu;
