import React, { useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import jet from '@randajan/react-jetpack';

import Core, { Article, Link, Img, Ico, Markdown, Pack, Nest, Caption, Tile  } from "@randajan/react-app-core";

import { Slider, ApiField} from "../../Core/Components";

import CountOn from "../CountOn/CountOn";

import Reviews from "../Reviews/Reviews";

import { Form as DBForm } from "../../DB/Components";


import "./Home.scss";

function Callto() {
  const lang = Core.use("lang");

  return (
    <Pack className="Callto wall">
      <div className="flex">
        <Article src="md.home.callto"/>
        <Img src="diamond"/>
      </div>
      <ApiField
        name="tel"
        maxLength={32}
        method="POST"
        url="db/care_calltos.webform/+"
        timeout={10000}
        echo={eng=>lang.spell(["form.callto", eng.state])}
      >
        <Ico src="tel"/>
      </ApiField>
    </Pack>
  )
}

function Home() {
  const lang = Core.use("lang");
  const [b2b, setB2b] = useState();
  return (
    <Pack.section className="Home">
      <div className="block landing">
        <Caption><Markdown>{lang.spell("map./.caption.main")}</Markdown></Caption>
        <Callto/>
      </div>
      <Nest className={jet.str.to(["block", "wall", "target", b2b ? "b2b" : "b2c"], " ")} caption={lang.spell("map./.caption.weare")}>
        <div className="tiles">
          <Tile onClick={_=>setB2b(false)} src={"home"} title={lang.spell("map./.caption.b2c")} className="home" />
          <Tile onClick={_=>setB2b(true)} src={"company"} title={lang.spell("map./.caption.b2b")} className="company" />
        </div>
        <div className="tabs">
          <div className="upline"/>
          <div className="grid">
            <Article src="md.home.b2c"/>
            <Article src="md.home.b2b"/>
          </div>
        </div>
      </Nest>
      <Nest className="block wall whyus" caption={lang.spell("map./.caption.whyus")}>
        <CountOn/>
      </Nest>
      <Nest className="block wall goal" caption={lang.spell("map./.caption.goal")}>
        <Article src="md.home.goal"/>
      </Nest>
      <div className="block wall reviews">
        <Reviews/>
      </div>
      <Pack className="block wall callus">
        <DBForm 
          entity={"care_calltos"}
          ui={"webform"}
          caption={lang.spell("map./.caption.callus")}
          result={<Nest level={1} caption={lang.spell("thx")}>{lang.spell("form.callto.result")}</Nest>}
          onPost={form=>form.reject()}
          createOnly 
        >
          <Article src="md.privacy.accept"/>
        </DBForm>
      </Pack>
    </Pack.section>
  );
}





export default Home;
