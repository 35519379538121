import React from 'react';


import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Link, Ico, Caption } from "@randajan/react-app-core";

import { Table } from "@randajan/react-form";

import DB from "../../Mods/DB";

import Mist from "../Mist/Mist";
import ViewFoot from "../ViewFoot/ViewFoot";
import buildField from "../Fields/Fields";


import "./View.scss";


function View(props) {
  const { className, entity, ui, page, labels, limit, cache, lookupUrl, modField, onPageChange, loadingWheel, caption, nocaption } = props;

  const lang = Core.use("lang");
  const { getting, meta, info, data } = DB.useGet(entity, ui, null, {page, limit}, cache);
  const { fields, division, plural } = meta;
  const name = DB.useCaption(lang, division, plural);

  const columns = {};

  Object.values(jet.obj.tap(fields)).sort((f1, f2)=>(f1.index-f2.index) || (f1.id - f2.id)).map(({alias, display})=>{
    if (display>0) { columns[alias] = jet.str.to(labels, alias) || lang.spell(["db.field", alias]) || jet.str.capitalize(alias); };
  });

  const rows = jet.arr.to(data).map(row=>jet.map.of(columns, (label, alias)=>
    buildField({meta, alias, rawput:row[alias], id:row.id, lookupUrl, modField})
  ));

  return (
    <div className={jet.str.to(["View", className], " ")}>
      <Table 
        caption={
          <Caption>
            {nocaption ? null : (caption || name)}
            {lookupUrl ? <Link to={lookupUrl(entity, "+")}>+</Link> : null }
          </Caption>
        }
        columns={columns}
        rows={rows}
        foot={<ViewFoot {...jet.obj.tap(info)} onPageChange={onPageChange}/>}
      />
      <Mist in={getting.pending}>
        {loadingWheel}
      </Mist>
    </div>
  );
}

export default View;
