import React, { useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Modal, Link, Img, Ico, Nest, Article } from "@randajan/react-app-core";

import { Form, Field, Range, Button, Bar } from "@randajan/react-form";

import { Slider, LoadingWheel, useHashPop } from "../../Core/Components";

import { DB } from "../../DB/Mods";

import { Form as DBForm } from "../../DB/Components";

import Rating from "./Rating/Rating";


import "./Reviews.scss";


function ReviewForm({onPost, result, children}) {
  const selfProps = {
    className:"ReviewForm",
    entity:"care_reviews",
    ui:"webform",
    result,
    onPost,
    modField:alias=>alias === "rating" ? {marker:<Rating/>} : null,
    loadingWheel:<LoadingWheel/>,
    children
  }

  return (
    <DBForm {...selfProps} createOnly/>
  )

}

function Review(props) {
  const { exp, fullname, source, rating } = props;
  const lang = Core.use("lang");
  return (
    <div className="Review">
      <Bar value={rating} step={10} marker={<Rating/>}/>
      <div className="quote">{exp}</div>
      <div className="sign">
        <div className="name">{fullname}</div>
        {source ? <Link to={source} target="_blank">{lang.spell("db.field.source")}</Link> : null}
      </div>
    </div>
  )
}

function Reviews() {
  const screen = Core.use("screen");
  const lang = Core.use("lang");
  const { getting, get, data } = DB.useView("care_reviews", "webview");
  const pop = useHashPop("review", 
    <ReviewForm 
      result={<Nest level={1} caption={lang.spell("thx")}>{lang.spell("form.review.result")}</Nest>}
      onPost={_=>{pop.down(); get()}}
    >
      <div>{lang.spell("form.review.nopublic")}</div>
      <Article src="md.privacy.accept"/>
    </ReviewForm>
  )
  return (
    <Nest className="Reviews" caption={lang.spell("form.review.caption")}>
      <Slider slidesToShow={screen.isSize(">m") ? 3 : screen.isSize(">s") ? 2 : 1} infinite={false}>
        {jet.map.it(data, (review, key)=><Review key={key} {...review}/>)}
      </Slider>
      <Button onSubmit={_=>pop.up()}>{lang.spell("form.review.add")}</Button>
    </Nest>
  )
}

export default Reviews;
