import React from 'react';

import Core, { Article } from "@randajan/react-app-core";


import "./Stories.scss";

function Stories () {
  const lang = Core.use("lang");

  return (
    <section className="Stories">
      <div className="block wall">
        <h1>{lang.spell("map./stories.caption.main")}</h1>
        <Article src={"md.stories.storyA"}/>
      </div>
      <Article src={"md.stories.storyB"} className="block wall orange"/>
      <Article src={"md.stories.storyC"} className="block wall"/>
      <Article src={"md.stories.storyD"} className="block wall orange"/>
      <Article src={"md.stories.storyE"} className="block wall"/>
    </section>
  );
}

export default Stories;
