import React from 'react';
import { Article } from "@randajan/react-app-core";
import "./Null.scss";

function Null () {
  return (
    <section className="Null">
        <Article src="md.null" className="block orange wall"/>
    </section>
  );
}

export default Null;
