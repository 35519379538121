import React from 'react';

import Hub from "../Hub/Hub";
import AdminMenu from "../AdminMenu/AdminMenu";
//import OrderPane from "../Eshop/OrderPane/OrderPane";

import "./Desk.scss";

function Desk () {
  return (
    <div className="Desk">
      <Hub/>
      <AdminMenu position="left"/>

      
      {/* <OrderPane/> */}
    </div>
  );
}

export default Desk;
