import React from 'react';

import jet from '@randajan/react-jetpack';

import { useParams } from "react-router-dom";

import Core, { Ico, Link } from "@randajan/react-app-core";

import { DB } from "../../DB/Mods";
import { View, Form } from "../../DB/Components";

import Rating from "../Reviews/Rating/Rating";

import "./Admin.scss";

function buildUrl(division, name, ui, id, search) {
  return "/"+DB.buildUrl("admin", jet.str.to([division, name], "/"), ui, id, search);
}

function Admin() {
  const { division, name, ui, id } = useParams();
  const db = Core.useSerf("db");
  const user = Core.use("auth.user.profile");
  const isForm = jet.type.is.full(id);
  const entity = db.findEntity(division, name, isForm);
  const [page, setPage] = Core.useKey("page.search.page");

  const propsSelf = {
    className:"block wall",
    entity,
    ui,
    id,
    builders:{},
    lookupUrl:(entity, id)=>{
      const lookup = db.get(["metadata", entity, "ui"]);
      return lookup ? buildUrl(lookup.division, lookup.singular, "ui", id) : null;
    },
    modField:alias=>alias === "rating" ? {marker:<Rating/>} : null
  };


  return (
    <section className="Admin">
      {
        !user.get("can_send_emails") ? null :
        isForm ? <Form {...propsSelf}/> : 
        <View {...propsSelf} page={page} onPageChange={setPage}/>
      }
    </section>
  );
}

export default Admin;
