import React from 'react';

import { Article } from "@randajan/react-app-core";

import "./Campaign.scss";

function Campaign () {
  return (
    <section className="Campaign">
      <Article src="md.campaign.free" className="block wall"/>
    </section>
  );
}

export default Campaign;
