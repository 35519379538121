import React from 'react';

import jet from "@randajan/jetpack";

import Core, { Ico } from "@randajan/react-app-core";

import { Button } from "@randajan/react-form";

import { Slider } from "../../../Core/Components";

import OrderControl from "../OrderControl/OrderControl";

import { Price, Numbox } from "../Helpers/Helpers";

import "./Item.scss";


const { REACT_APP_API_URL } = process.env;

const SLICK_SETTINGS = {
  slidesToShow: 1,
  slidesToScroll: 1,
};


function Item(props) {
  const { id, row, tile, className, onBuy, onOpen } = props;
  const data = jet.obj.tap(Core.useVal(["eshop.items", id]));
  let amount = jet.num.to(Core.useVal(["eshop.order.items", id, "amount"]));

  const { name, description, details, images, warranty } = data;
  let { in_stock, price, fullprice } = data;

  amount = jet.num.tap(amount);
  price = jet.num.to(price);
  fullprice = jet.num.to(fullprice);
  const discount = jet.num.frame(price/fullprice-1, -1, 0);

  const totalprice = price*amount;
  
  const CN = ["Item", discount ? "discount" : "full", className];

  if (row) {
    CN.push("row");
    return (
      <div className={jet.str.to(CN, " ")}>
        <div className="image">
          <img src={REACT_APP_API_URL+"/img/eshop/"+jet.map.dig(images, "0.uri", "noimg.png")}/>
        </div>
        <a onClick={_=>jet.fce.run(onOpen, id)}>{name}</a>
        <Price className="endprice" value={price}/>
        <Price className="totalprice" value={totalprice}/>
        <div className="control">
          <OrderControl itemId={id}/>
        </div>
      </div>
    )
  }

  if (tile) {
    CN.push("tile");
    return (
      <div className={jet.str.to(CN, " ")}>
        <img src={REACT_APP_API_URL+"/img/eshop/"+images[0].uri}/>
        <h3><a onClick={_=>jet.fce.run(onOpen, id)}>{name}</a></h3>
        <Numbox {...{fullprice, price, in_stock, discount}}/>
        <OrderControl itemId={id} onClick={_=>jet.fce.run(onBuy, id)}/>
      </div>
    )
  }

  CN.push("page");
  return (
    <section className={jet.str.to(CN, " ")}>
        <h1>{name}</h1>
        <div className="grid">
          <div className="images">
            <Slider {...SLICK_SETTINGS}>
              {jet.arr.tap(images).map((img, k)=><img key={k} src={REACT_APP_API_URL+"/img/eshop/"+img.uri}/>)}
            </Slider>
          </div>
          <div className="description">
            <h2>Popis</h2>
            <div>{description}</div>
            <div className="flex">
              <Numbox {...{fullprice, price, in_stock, warranty, discount}}/>
              <OrderControl itemId={id} onClick={_=>jet.fce.run(onBuy, id)}/>
            </div>
          </div>
      </div>
      <div className="details">
        <h2>Specifikace</h2>
        <table>
          <tbody>
            {jet.arr.tap(details).map((v,k)=><tr key={k}>
              <td className="name">{jet.str.capitalize(v.name)}</td>
              <td className="desc">{v.description}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </section>
  );
  

}

export default Item;
