import React from 'react';
import SlickSlider from "react-slick";

import { Ico } from "@randajan/react-app-core";

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import "./Slider.scss";

const DEFAULTPROPS = {
  arrows: true,
  infinite: true,
  speed: 500,
  nextArrow: <Arrow src="right"/>,
  prevArrow: <Arrow src="left"/>
};

function Arrow({ src, onClick, className }) {
  return <Ico {...{ src, onClick, className }}/>
}

function Slider (props) {
  return <SlickSlider {...{...DEFAULTPROPS, ...props}}/>
}

export default Slider;
