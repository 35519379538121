import React from 'react';

import  { Article } from "@randajan/react-app-core";

import "./CountOn.scss";


function CountOn() {
  return (
    <Article src={"md.home.countonus"} inView={{triggerOnce:false, threshold: .8}} className={["CountOn", "grid"]}/>
  );
}

export default CountOn;
