import React from 'react';

import jet from "@randajan/react-jetpack";

import { Ico, Link } from "@randajan/react-app-core";

import { Field, Switch, Bar, Range } from "@randajan/react-form";

import DB from "../../Mods/DB";

import "./Fields.scss";

function Empty() {
  return <div className="Empty">~</div>
}

function Img({value}) {
  return <img className="Img" src={value}/>;
}

function Checkbox({value}) {
  return <Ico className="Checkbox" src={value?"full":"blank"}/>;
}

function Timegulf({value}) {
  const now = new Date();
  value = jet.date.to(value);

  return <span className="Timegulf" title={value}>{jet.amount(Math.abs((now - value)), "ms", 0).toString()}</span>
}

function Text({value}) {
  const fullvalue = value = jet.str.to(value);

  if (value.length > 50) { value = value.substring(0, 47)+"..."; }

  return (
    <span className="Text" title={fullvalue}>
      {value}
    </span>
  )
}

function buildLink(props) {

  const { meta, alias, rawput, value, field, id, lookupUrl } = props;
  const metafield = meta.fields[alias];
  const { style, clue, relation, display, min, max, step, decimal, primitive, nullable } = metafield;

  return (
    (lookupUrl && clue) ? <Link to={lookupUrl(meta.entity, id)}>{field}</Link> :
    !rawput ? field :
    (lookupUrl && relation) ? <Link to={lookupUrl(rawput.entity, rawput.id)}>{field||<Ico src="search"/>}</Link> :
    style === "url" ? <Link target="_blank" to={value}>{field||<Ico src="url"/>}</Link> :
    style === "email" ? <Link to={"mailto:"+value} >{field||<Ico src="email"/>}</Link> :
    style === "tel" ? <Link to={"tel:"+value} >{field||<Ico src="tel"/>}</Link> :
    field
  );
}


function buildField(props) {

  const { meta, alias, id, editable, lookupUrl, modField, rawput, output } = props;
  const { entity, division, plural, singular, readonly, stateful } = meta;

  const metafield = meta.fields[alias];
  const { style, clue, relation, lock, display, min, max, step, decimal, primitive, nullable, column } = metafield;

  if (editable) { 
    if (column !== alias) { return null; }
    if (stateful && ["state", "owner", "create", "creator", "update", "updater"].includes(alias)) { return null; }
    
    const propsSelf = {
      key:alias,
      name:alias,
      label:alias,
      lock:readonly||lock,
      flags:{hidden:_=>!display, recommend:_=>display === 2, required:_=>display === 3},
      rawput:jet.type.to(primitive, jet.type.full.any(rawput, metafield.default)),
      output:jet.type.to(primitive, jet.type.full.any(output, rawput, metafield.default)),
      ...jet.obj.to(modField, alias)
    }

    const link = buildLink(props);

    if (style === "checkbox") { return <Switch {...propsSelf}>{link}</Switch> }
    if (style === "range") { return <Range {...propsSelf} from={min} to={max} step={step} /> }

    if (!relation && primitive === "str") { propsSelf.maxLength = max; }
    if (style === "text" || style === "note" || style === "html") {
      propsSelf.type = "textarea";
      propsSelf.rows = 3;
      propsSelf.autoSize = true;
    }

    return <Field {...propsSelf}>{link}</Field>
  } else {
    
    const value = relation ? jet.map.dig(rawput, "value") : rawput; //lookup

    const propsSelf = {
      key:alias,
      value:jet.type.to(primitive, value),
      from:min, to:max, step:step,
      ...jet.obj.to(modField, alias)
    }

    return buildLink({lookupUrl, meta, alias, rawput, value, id, field:
      !jet.type.is.full(value) ? <Empty {...propsSelf}/> :
      style === "range" ? <Bar {...propsSelf}/> :
      style === "img" ? <Img {...propsSelf}/> :
      style === "checkbox" ? <Checkbox {...propsSelf}/> :
      style === "timegulf" ? <Timegulf {...propsSelf}/> :
      <Text {...propsSelf}/>
    });

  }

}

export default buildField
export {
  Empty,
  Img,
  Checkbox,
  Timegulf,
  Text,
}
