import React from 'react';

import jet from "@randajan/jetpack";


import "./Helpers.scss";

function Price(props) {
  const { className, value } = props;
  return (
    <span className={jet.str.to(["Price", className], " ")}>
      {jet.num.to(value).toLocaleString()},- Kč
    </span>
  )
}

function Percent(props) {
  const { className, value } = props;
  return (
    <span className={jet.str.to(["Percent", className], " ")}>
      {Math.round(jet.num.to(value)*100)}%
    </span>
  )
}

function Warranty(props) {
  const { className, value } = props;
  return (
    <span className={jet.str.to(["Warranty", className], " ")}>
      {jet.num.to(value)} měsíců
    </span>
  )
}

function Stock(props) {
  const { className, value } = props;
  return (
    <span className={jet.str.to(["Stock", className], " ")}>
      {jet.num.to(value)} ks
    </span>
  )
}

function Discount(props) {
  const { className, fullprice, discount } = props;

  return (
    <span className={jet.str.to(["Discount", className], " ")}>
      <Price className="fullprice" value={fullprice}/>
      <Percent value={discount}/>
    </span>
  ) 
}

function Numbox(props) {
  const { in_stock, warranty, price, fullprice, discount } = props;
  return (              
    <table className={jet.str.to(["Numbox"], " ")}>
      <tbody>
        <tr><td>Cena</td><td><Price className="endprice" value={price}/></td></tr>
        <tr style={{visibility:discount?"visible":"hidden"}}><td>Původní cena</td><td><Discount {...{price, fullprice, discount}}/></td></tr>
        <tr><td>Skladem</td><td><Stock value={in_stock}/></td></tr>
        { !warranty ? null : <tr><td>Záruka</td><td><Warranty value={warranty}/></td></tr> }
      </tbody>
    </table>
  )
}

export {
  Price,
  Percent,
  Warranty,
  Discount,
  Stock,
  Numbox
};
