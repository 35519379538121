import React from 'react';

import jet from "@randajan/react-jetpack";

import Core, { Ico } from "@randajan/react-app-core";


import "./ViewFoot.scss";

function Page(props) {
  let { label, current, last, onPageChange } = props;

  const allowLeft = current > 1;
  const allowRight = current < last;

  return (
    <div className={"Page"}>
      <div className="label">{label}</div>
      <Ico className={allowLeft?"active":"pasive"} src="left" onClick={_=>allowLeft ? onPageChange(current-1) : null}/>
      <div className="fraction">{ jet.str.to([current, last], "/") }</div>
      <Ico className={allowRight?"active":"pasive"} src="right" onClick={_=>allowRight ? onPageChange(current+1) : null}/>
    </div>
  )
}

function Count(props) {
  const { label, count, size } = props;

  

  return (
    <div className="Count">
      <div className="label">{label}</div>
      <div className="fraction">{ jet.str.to([count, size], "/") }</div>
    </div>
  )
}

function ViewFoot (props) {
  const lang = Core.use("lang");
  const { onPageChange } = props;
  const [ count, size, page, lastpage ] = ["count", "size", "page", "lastpage"].map(v=>jet.num.to(props[v]));

  return (
    <div className={"ViewFoot"}>
      <Count count={count} size={size} label={lang.spell("db.solid.count")}/>
      <Page current={page} last={lastpage} label={lang.spell("db.solid.page")} onPageChange={onPageChange}/>
    </div>
  )
}

export default ViewFoot;
