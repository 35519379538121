import React, { useRef, useState, useEffect } from 'react';

import jet, { useEngage, useForceRender } from "@randajan/react-jetpack";

import Core, { Modal, Ico, Link, Help, Nest, Pack, Caption } from "@randajan/react-app-core";

import { Form, Table, Range, Field, Switch, Button } from "@randajan/react-form";


import { Percent, Currency, } from "../../Core/Components/Lots/Lots";

import "./Pricelist.scss";

function Item(props) {
  const { desc, help } = props;

  return (
    <div className="Item" title={help}>
      {desc}
      {help ? <Help>{help}</Help>:null }
    </div>
  )
}


function Pricelist () {
  const lang = Core.use("lang");
  const page = Core.useSerf("page");
  const prices = Core.useVal("care.prices.data");

  const rows = {};
  
  jet.map.it(prices, v=>{
    const value = jet.num.round(v.value);
    (rows[v.type] = rows[v.type] || []).push({
      name:<Item {...v}/>,
      value:v.israte == "1" ? <Percent value={value}/> : <Currency value={value}/>,
      unit:v.unit ? lang.spell(["units", v.unit]) : ""
    });
  });

  return (
    <section className="Pricelist">
      <Nest className="block wall" caption={lang.spell("map./price/list.caption.main")}>
        <Nest caption={lang.spell("map./price/list.caption.service")}>
          <Table columns={{name:lang.spell("db.field.desc"), value:lang.spell("db.field.price"), unit:lang.spell("db.field.unit")}} rows={rows.service}/>
        </Nest>
        <Nest caption={lang.spell("map./price/list.caption.charge")}>
          <Table columns={{name:lang.spell("db.field.desc"), value:lang.spell("db.field.charge")}} rows={rows.charge}/>
          <div className="note">
            {lang.spell("map./price/list.note.charge")}
          </div>
        </Nest>
        <Nest caption={lang.spell("map./price/list.caption.flatrate")}>
          <Table columns={{name:lang.spell("db.field.desc"), value:lang.spell("db.field.pricepp")}} rows={rows.flatrate}/>
          <Button onSubmit={_=>page.set("pathname", "/price/calc")}>
            {lang.spell("map./price/list.caption.calc")}
          </Button>
        </Nest>      
      </Nest>
    </section>
  );
}

export default Pricelist;
