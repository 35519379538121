import React from 'react';

import { CSSTransition } from "react-transition-group";

import "./Mist.scss";

function Mist(props) {
  return (
    <CSSTransition in={props.in} timeout={600} unmountOnExit>
      <div className="Mist">
        {props.children}
      </div>
    </CSSTransition>
  );
}

export default Mist;
