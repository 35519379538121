import React from 'react';

import Core, { Img } from "@randajan/react-app-core";


import "./LangMenu.scss";

function LangMenu() {
  const lang = Core.use("lang");
  return (
    <div className="LangMenu">
      {
        lang.get("list").map(v=>
          <div key={v} className={["LangFlag", v===lang.get("now") ? "now" : "option"].join(" ")}>
            <Img 
              src={"flags/"+v} 
              title={lang.spell("lang."+v)}
              onClick={_=>lang.set("now", v)}
            />
          </div>
        )
      }
      
    </div>
  );
}

export default LangMenu;
