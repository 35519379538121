import React from 'react';

import jet from "@randajan/jetpack";


import Item from "../Item/Item";

import "./Items.scss";

function Items(props) {
  const { table, data, className, onBuy, onOpen } = props;

  return (
    <div className={jet.str.to(["Items", className, table?"table":"grid"], " ")}>
      {jet.arr.to(data).map(props=>props ? 
        <Item {...{id:props.id, key:props.id, tile:!table, row:table, onBuy, onOpen}}/>
      : null)}
    </div>
  );
}

export default Items;
