import React from 'react';

import jet from "@randajan/jetpack";

import { Avatar } from "@randajan/react-app-core";


import "./UserAvatar.scss";

function UserAvatar({nickname, fullname, email, provider, avatar}) {
  if (nickname && nickname !== fullname) { fullname += " ("+nickname+")"; }
  
  const pass = {
    title:jet.map.melt([fullname, email, provider], "\n"),
    src:avatar,
    className:["UserAvatar", avatar ? "real" : "dummy"]
  }
  
  return <Avatar {...pass}/>;
}

export default UserAvatar;
