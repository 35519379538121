import React, { useEffect } from 'react';

import jet from "@randajan/jetpack";

import { CSSTransition } from "react-transition-group";

import Core, { Modal } from '@randajan/react-app-core';

import LoadingWheel from "../LoadingWheel/LoadingWheel";

import "./TrayBar.scss";

function TrayBar() {
  const tray = Core.use("tray");
  const pop = Modal.usePop();
  
  useEffect(_=>{
    if (tray.isError()) {
      pop.up({lock:!tray.parent.build.is("result"), children:tray.getError().map((v,k)=><p key={k}>{v}</p>)});
    }  
  })

  return (
    <CSSTransition in={tray.isPending()} timeout={{enter:600, exit:1200}} unmountOnExit appear>
      <div className={jet.str.to(["TrayBar"], " ")}>
        <LoadingWheel/>
        <div className="pending">
          {tray.getPending().map((v,k)=><p key={k}>{v}</p>)}
        </div>
      </div>
    </CSSTransition>
  );
}

export default TrayBar;
