import React from 'react';

import jet from "@randajan/jetpack";

import Core, { Ico } from "@randajan/react-app-core";

import { Button } from "@randajan/react-form";

import "./OrderControl.scss";

function OrderControl(props) {
  const { itemId, className, onClick } = props;
  const order = Core.use("eshop.order");
  const amount = itemId ? order.get(["items", itemId, "amount"]) : order.get("amount");
  const pass = {
    ...props,
    className:jet.map.melt(["OrderControl", amount ? "full" : "empty", className], " ")
  };
  
  delete pass.itemId;
  delete pass.onClick;

  return (
    <div {...pass}>
      {itemId ? <a><Ico className="noselect" src="rem" onClick={_=>order.putItem(itemId, -1)}/></a>:null}
      <div className="counter">
        <Ico src="cart"/>
        <div className="amount noselect">{amount||""}</div>
      </div>
      {itemId ? <a><Ico className="noselect" src="add" onClick={_=>order.putItem(itemId, 1)}/></a>:null}
      {onClick ? <Button onSubmit={_=>jet.fce.run(onClick, order, itemId)}>Koupit hned</Button> : null}
    </div>
  )
}

export default OrderControl;
