import React from 'react';

import jet from "@randajan/react-jetpack";

import Core from "@randajan/react-app-core";

import "./Lots.scss";

function numberWithCommas(x) {
  return x.toString();
}

function Currency({value, decimal}) {
  const lang = Core.use("lang");
  value = jet.num.round(value, decimal);
  value = jet.str.to(value);
  value = value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
  return (
    <span className="Lot Currency">
      {value + " " + lang.spell("currency")}
    </span>
  )
}

function Percent({value, decimal}) {
  return (
    <span className="Lot Percent">
      {jet.num.round(value, decimal) + " %"}
    </span>
  )
}

export {
  Currency,
  Percent
};

