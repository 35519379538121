import React from 'react';

import Core from "@randajan/react-app-core";
import "@randajan/react-form/dist/index.css";

import config from "../Core/config";

import Nav from "./Nav/Nav";
import Desk  from "./Desk/Desk";
import Foot from "./Foot/Foot";

import CokieNote from "./Cookies/Note";

import './Styles/App.scss';
import './Styles/Modal.scss';
import "./Styles/Forms.scss";
import "./Styles/Special.scss";
import "./Styles/Responsive.scss";
import "./Styles/Animation.scss";
import "./Styles/Background.scss";

import "./Styles/Avatar.scss";
import "./Styles/Menu.scss";
import "./Styles/Link.scss";
import "./Styles/Tile.scss";

function App() {

  return (
    <Core className="App" {...config}>

      <Nav />
      <Desk />
      <CokieNote/>
      <Foot />
    </Core>
  );
}

export default App;