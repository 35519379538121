import React from 'react';
import Core, { Article, Nest, Pack } from "@randajan/react-app-core";

import "./About.scss";

function About() {
  const lang = Core.use("lang");
  return (
    <section className="About">
      <Nest className="block wall" caption={lang.spell("map./about.caption.main")}>
        <Pack className="grid">
          <Article src="md.about.who"/>
          <Article src="md.about.why"/>
        </Pack>
        <Article src="md.about.solong" className="solong"/>
      </Nest>
    </section>
  );
}

export default About;
