import React from 'react';

import jet from "@randajan/jetpack";

import { DB } from "../../DB/Mods";

import Core, { Ico, Link } from "@randajan/react-app-core";

import { Menu } from "@randajan/react-form";

import "./AdminMenu.scss";

function buildUrl(division, name, gui, id, search) {
  return "/"+DB.buildUrl("admin", jet.str.to([division, name], "/"), gui, id, search);
}

function AdminMenu ({position}) {
  const db = Core.useSerf("db");
  const show = Core.useVal("auth.user.profile.can_send_emails");
  const lang = Core.use("lang");

  if (!show) { return null; }
  
  return (
    <div className="AdminMenu">
      {Object.entries(db.get("index")).reverse().map(([division, entities])=>
        <Menu key={division} className="Division" trigger={<Ico src={"db/"+division}/>} transition={600} position={position}>
          {Object.entries(entities.plural).reverse().map(([plural, entity])=>
            <Link key={plural} to={buildUrl(division, plural)}>
              {DB.useCaption(lang, division, plural)}
            </Link>
          )}
        </Menu>
      )}
    </div>
  );
}

export default AdminMenu;
