import React from 'react';

import jet from "@randajan/react-jetpack";

import Core, { Nest } from "@randajan/react-app-core";

import { Timegulf } from "../Fields/Fields";

import "./HttpError.scss";

const errList = [429, 404, 405, 403, 401];

function HttpError({error}) {
  const lang = Core.use("lang");
  const res = error ? error.response : null;
  let status = jet.map.dig(res, "status");
  let message = jet.map.dig(res, "json.message");

  status = errList.includes(status) ? "e"+status : "unknown";

  return (
    <Nest level={1} className="HttpError" data-status={status} caption={lang.spell(["db.err", status, "caption"])}>
      {lang.spell(["db.err", status, "detail"])}
      {status === 429 ? <Timegulf value={message}/> : null}
    </Nest>
  )
}


export default HttpError;
