import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Core, { Pack, Modal } from "@randajan/react-app-core";

import Home from "../Home/Home";
import About from "../About/About";
import Services from "../Services/Services";
import User from "../User/User";
import Campaign from "../Campaign/Campaign";
import Pricelist from "../Pricelist/Pricelist";
import OgImage from "../Og/OgImage";
import Null from "../Null/Null";
import Calculator from "../Calculator/Calculator";

import Eshop from "../Eshop/Eshop";
import Stories from "../Stories/Stories";
import Privacy from "../Privacy/Privacy";

import Admin from "../Admin/Admin";

import "./Hub.scss";

const exact = true;

const ROUTES = [
  { path:"/", component:Home, exact },
//  { path:"/campaign/free", component:Campaign, exact },
  { path:"/services", component:Services, exact },
  { path:"/price/list", component:Pricelist, exact },
  { path:"/about", component:About, exact },
  { path:"/price/calc", component:Calculator, exact },
//  { path:"/eshop", component:Eshop, exact },
  { path:"/stories", component:Stories, exact},
  { path:"/user", component:User, exact },
  { path:"/user/privacy", component:Privacy, exact },
  { path:"/admin", component:Admin, exact },
  { path:"/admin/:division/:name.:ui/:id", component:Admin, exact },
  { path:"/admin/:division/:name/:id", component:Admin, exact },
  { path:"/admin/:division/:name.:ui", component:Admin, exact },
  { path:"/admin/:division/:name", component:Admin, exact },

//  { path:"/ogimage", component:OgImage},
  { path:"*", component:Null}
]

function Hub(props) {
  const { location } = props;

  const page = Core.useSerf("page");
  const modal = Modal.use();

  //close all pops on page change
  useEffect(_=>page.eye("pathname", _=>modal.pops.all.forEach(pop=>pop.down())), []);

  return (
    <TransitionGroup className="Hub">
      <CSSTransition key={location.pathname} timeout={600} appear>
        <Switch location={location}>
          {ROUTES.map((route, key)=><Route key={key} {...route}/>)}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )

}

export default withRouter(Hub)