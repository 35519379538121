import React, { useRef, useState, useEffect } from 'react';

import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Article, Modal, Ico, Pack, Nest, Caption } from "@randajan/react-app-core";

import { Table, Range, Switch, Button, Pane, Field, Form } from "@randajan/react-form";

import { Percent, Currency } from "../../Core/Components/Lots/Lots";

import { Form as DBForm } from "../../DB/Components";

import "./Calculator.scss";

const clang = {
  labels:{
    detailed:"Podrobné nastavení",
    acc:"Počet uživatelů",
    pc:"Počet terminálů",
    netgear:"Počet síťových prvků",
    gear:"Počet periferií",
    server:"Počet serverů",
    service:"Počet sdílených služeb",
    services_user:"Počet uživatelů sdílených služeb",
    workday:"Pracovní dny",
    weekend:"Víkend",
    labor:"Běžný servis do",
    urgent:"Urgentní servis do"
  }
}

const rawput = {
  acc:1,
  pc:1,
  netgear:0,
  gear:0,
  server:0,
  service:0,
  services_user:0,
  workday:1,
  weekend:0,
  labor:48,
  urgent:8
}

const temp = {
  range:[" - ", "8:00 - 17:00", "6:00 - 22:00", "Nonstop"]
}

function countprice(input, flatrate) {
  let { detailed, acc, pc, netgear, gear, server, service, services_user, workday, weekend, labor, urgent } = input

  let scope = 0;
  let range = 0;
  let speed = 0;

  pc = detailed ? pc : acc;
  netgear = detailed ? netgear : 0;
  gear = detailed ? gear : 0;
  service = detailed ? service : server;
  services_user = (detailed ? services_user : acc) * service;
 
  scope += acc * flatrate.acc;
  scope += pc * flatrate.pc;
  scope += netgear * flatrate.netgear;
  scope += gear * flatrate.gear;
  scope += server * flatrate.server;
  scope += service * flatrate.service;
  scope += services_user * flatrate.services_user;

  range = workday+weekend-1;
  speed += 4-jet.num.whatpow(labor/3, 2);
  speed += 3-jet.num.whatpow(urgent, 2);

  range *= scope*.25; //constant rate
  speed *= scope*.25; //constant rate

  let full = scope + range + speed;

  return {scope, range, speed, full};
}

function Calculator() {
  const lang = Core.use("lang");
  const prices = Core.useVal("care.prices.data");
  const page = Core.use("page");

  const pop = Modal.usePop();

  const output = page.get("search");
  const flatrate = {};
  const input = jet.obj.full(output, rawput);
  
  jet.map.it(prices, v=>v.type === "flatrate" ? flatrate[v.tag] = jet.num.to(v.value) : undefined);
  
  let price = countprice(input, flatrate);

  const propsForm = {
    labels:clang.labels, className:"grid", rawput, output,
    onInput:form=>page.set("search", jet.map.of(form.getInput(), v=>jet.num.to(v)))
  }

  return (
    <section className="Calculator">
      <Nest className="block wall" caption={lang.spell("map./price/calc.caption.main")}>
        <Form {...propsForm}>
          <Nest className="flex scope" caption={lang.spell("map./price/calc.caption.scope")}>
            <Switch name="detailed"/>
            <Field name="acc" type="number"/>
            <Pane expand={!!input.detailed} transition={600}>
              <Field name="pc" type="number"/>
              <Field name="netgear" type="number"/>
              <Field name="gear" type="number"/>
            </Pane>
            <Field name="server" type="number"/>
            <Pane expand={!!(input.detailed && input.server > 0)} transition={600}>
              <Field name="service" type="number"/>
              <Field name="services_user" type="number"/>
            </Pane>
            <div className="price">
              <Currency value={price.scope}/>
            </div>
          </Nest>

          <Nest className="flex range" caption={lang.spell("map./price/calc.caption.range")}>
            <div className="grid">
              <Range name="workday" from={0} to={3} min={1} step={1}/>
              <span className="value">{temp.range[input.workday]}</span>
              <Range name="weekend" from={0} to={3} step={1}/>
              <span className="value">{temp.range[input.weekend]}</span>
            </div>
            <div className="price">
              <span>+</span>
              <Currency value={price.range}/>
            </div>
          </Nest>

          <Nest className="flex speed" caption={lang.spell("map./price/calc.caption.speed")}>
            <div className="grid">
              <Range name="labor" from={48} to={12} step={1}/>
              <span className="value">{input.labor} hodin</span>
              <Range name="urgent" from={8} to={2} step={1}/>
              <span className="value">{input.urgent} hodin</span>
            </div>
            <div className="price">
              <span>+</span>
              <Currency value={price.speed}/>
            </div>
          </Nest>

        </Form>
        <div className="fullprice">
            <span>Měsíční plnění</span>
            <Currency value={price.full}/>
        </div>
        <div className="callto">
          <Button onSubmit={_=>pop.up(
            <DBForm
              entity={"care_calltos"} 
              ui={"webform"} 
              output={{source:page.get("path")}}
              caption={lang.spell("map./price/calc.caption.form")}
              result={<Nest level={1} caption={lang.spell("thx")}>{lang.spell("form.callto.result")}</Nest>}
              onPost={_=>pop.down()}
              createOnly
            >
              <Article src="md.privacy.accept"/>
            </DBForm>
          )}>
            {lang.spell("map./price/calc.caption.send")}
          </Button>
        </div>
      </Nest>
    </section>
  )
}

export default Calculator;
