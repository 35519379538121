import React, { useState } from 'react';

import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Ico } from "@randajan/react-app-core";

import { Field } from "@randajan/react-form";

import "./ApiField.scss";

function ApiField(props) {
  const { url, name, timeout, children, echo, onSend, lock, method } = props;

  const api = Core.useApi();
  const [eng, prev, setEng] = useEngage(body=>{
    if (body) { return jet.eng(api.fetch(method||"POST", url, body), timeout).echo(echo); }
  });

  const propsField = {
    ...props,
    className:"ApiField",
    lock:lock || eng.pending,
    flags:{state:eng.state, send:f=>!!f.getOutput()},
    onOutput:(f, val)=>{
      if (!val) { return setEng(); }
      let body = {[name]:val};
      setEng(jet.fce.is(onSend) ? onSend(body) : body);
    }
  }

  return (
    <Field {...propsField}>
      <div className="status-ico">
        <Ico src="load" className="loading-spin"/>
        <Ico src="err"/>
        <Ico src="ok"/>
      </div>
      <div className="status-msg">
        {eng ? eng.msg : ""}
      </div>
      {children}
    </Field>
  );
}

export default ApiField;
