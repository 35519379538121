import React from 'react';

import { Article } from "@randajan/react-app-core";
import "./Privacy.scss";

function Privacy () {
  return (
    <section className="Privacy">
      <div className="block wall">
        <Article src="md.privacy.info"/>
      </div>
    </section>
  );
}

export default Privacy;
