import React, { useEffect } from 'react';
import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Serf, Page } from "@randajan/react-app-core";

class DB extends Serf {

    static responseToResult(response, lastResponse, id, defaultMeta) {
        const { data, info, meta, errors } = jet.obj.tap(response.result, lastResponse ? lastResponse.result : null);
        return {
            data:jet.type.tap((id === "new" || jet.type.is.full(id)) ? "obj" : "arr", data),
            info:jet.obj.tap(info),
            meta:jet.obj.tap(meta, defaultMeta),
            errors
        }
    }

    static useCaption(lang, division, plural, singular, ui) {
        const ent = lang.spell(["db.metadata", division, "entity", plural]);
        const def = singular || plural;
        if (!ent) { return def; }
        const n = singular ? 0 : 1;
        return (ent[ui] ? (ent[ui][n] || ent[ui][0]) : null) || (ent.ui ? (ent.ui[n] || ent.ui[0]) : null) || def;
    }

    static useGet(entity, ui, id, search, cache, onGet) {
        ui = jet.str.to(ui) || "ui";
        const db = Core.useSerf("db");
        const metadata = jet.obj.tap(db.get(["metadata", entity, ui]));
        const skipget = (id === "+" && metadata.fields);
        const [ getting, got, get ] = useEngage(_=>skipget ? null : db.query("GET", entity, ui, id, search), cache, entity, ui, id, jet.obj.json.to(search));
        useEffect(_=>{jet.fce.run(onGet, getting);}, [getting, getting.state]);
        return { db, getting, get, ...DB.responseToResult(getting, got, id, metadata) }
    }

    static usePost(entity, ui, id, search, onPost) {
        ui = jet.str.to(ui) || "ui";
        const db = Core.useSerf("db");
        const [ posting, posted, post ] = useEngage(async body=>body ? await db.query("POST", entity, ui, id, search, body) : null);
        useEffect(_=>{jet.fce.run(onPost, posting);}, [posting, posting.state]);
        return { db, posting, post, ...DB.responseToResult(posting, posted, id)  }
    }

    static useView(entity, ui, page, limit, cache, onSave) {
        return DB.useGet(entity, ui, null, {page, limit}, cache, onSave);
    }

    static buildUrl(prefix, entity, ui, id, search) {
        return jet.str.to([jet.str.to([prefix, jet.str.to([entity, ui === "ui" ? null : ui], "."), id], "/"), Page.buildSearch(search)], "?");
    }

    constructor(core, path, prefix) {
        super(core, path);

        this.fitDefault("def.prefix", "db");

        this.fitType("metadata", "obj");

        this.set("def", { prefix });

        jet.obj.prop.add(this, "build", core.tray.watch(
            Promise.all([
                    this.storeLocal("store").fill(),
                    this.setMetadata()
            ]),
            g=>core.lang.spell(["db.watch.metadata", g.state])
        ));
      
    }

    findEntity(division, name, isSingular) {
        return this.get(["index", division, isSingular ? "singular" : "plural", name]);
    }

    async query(method, entity, ui, id, search, body, headers) {
        const metapath = ["metadata", entity, ui];
        const path = "/"+DB.buildUrl(this.get("def.prefix"), entity, ui, id, search);

        headers = jet.obj.tap(headers);
        if (this.isFull([metapath, "fields"])) { headers["x-nometa"] = true; }

        const result = await this.parent.api.fetch(method, path, body, headers);
        if (!result) { return; }
        if (result.meta) { this.set(metapath, result.meta); }
        else { result.meta = this.get(metapath); }
        return result;
    }

    async setMetadata() {
        const index = {};
        const metadata = {};
        jet.map.it(await this.parent.api.get("db"), v=>{
            const { division, plural, singular, entity, ui } = v;
            jet.map.put(index, [division, "singular", singular], entity);
            jet.map.put(index, [division, "plural", plural], entity);
            jet.map.put(metadata, [entity, ui], v);
        });
        this.push({metadata, index});
    }

}


export default DB;