import React, { useState, useRef } from 'react';

import jet from "@randajan/jetpack";

import Core, { Ico, User, Lang } from "@randajan/react-app-core";
import { Form, Field, Button } from "@randajan/react-form";


import "./ContactForm.scss";

function ContactForm (props) {
  const { className, type, lock, onSubmit } = props;
  const lang = Core.use("lang");
  const contact = Core.use("eshop.order.contact");

  const selfProps = {
    className:["ContactForm", lock?"table":"", className],
    labels:f=>lang.spell(["field", f]),
    titles:f=>lang.spell(["field", f]),
    output:contact.get(type),
    flags:{ company:form=>form.getInput()["ic"] },
    lock,
  }

  if (lock) {
    return (
      <Form {...selfProps}>
        <Field name={type+"way"} rawput={lang.spell(["eshop", type, "way"])}/>
        <Field name="ic"/>
        <Field name="fullname" className="fullname"/>
        <Field name="company" className="company"/>
        <Field name="tel"/>
        <Field name="email"/>
        <Field name="fullstreet"/>
        <Field name="city"/>
        <Field name="zip"/>
      </Form>
    )
  }

  return (
    <Form {...selfProps} onOutput={(form, output)=>{ contact.set(type, output); }}>
      <h1>{lang.spell(["eshop", type, "h1"])}</h1>
      <div className="grid">
        <div className="way">
          <Field name={type+"way"} lock rawput={lang.spell(["eshop", type, "way"])}/>
        </div>

        <div className="person">
          <Field name="ic" maxLength={16}/>
          <Field name="fullname" className="fullname" maxLength={64}/>
          <Field name="company" className="company" maxLength={64}/>
          <Field name="tel" maxLength={64}/>
          <Field name="email" maxLength={64}/>
        </div>
        <div className="address" maxLength={64}>
          <Field name="fullstreet" maxLength={64}/>
          <Field name="city" maxLength={64}/>
          <Field name="zip" maxLength={64}/>
        </div>
      </div>
      <div className="controls">
        <Button type="rejectInput" onSubmit={but=>{but.props.parent.rejectInput(); jet.fce.run(onSubmit);}} lock={false}>
          <Ico src="undo" title="Vrátit úpravy"/>
        </Button>
        <Button type="submitInput" onSubmit={but=>{but.props.parent.submitInput(); jet.fce.run(onSubmit);}} >
          <Ico src="ok" title="Potvrdit úpravy"/>
        </Button>
      </div>
    </Form>
  );
}

export default ContactForm;
