import React from 'react';
import Core, { Img, Link } from "@randajan/react-app-core";

import { UserMenu } from "../../Core/Components";
//import { OrderControl } from "../../Eshop/Components";

import MainMenu from "../MainMenu/MainMenu";

import "./Nav.scss";
import "./Menu.scss";

function Nav () {
  const flat = Core.useVal("screen.>s");

  return (
      <header className="Nav wall">
        <div className="block">
          <Link exact to="/">
            <Img src={"logo"} />
          </Link>
          <div className="right">
            <MainMenu flat={flat} subposition="top-right"/>
            {/* <PaneTrigger corekey="pane.order" transition=".5s">
              <OrderControl/>
            </PaneTrigger> */}
            <UserMenu position="top"/>
          </div>
        </div>
      </header>
  );
}

export default Nav;