import React from 'react';

import jet from "@randajan/react-jetpack";

import Core, { Avatar, Nest, Pack } from "@randajan/react-app-core";

import { Form as DBForm } from "../../DB/Components";


import "./User.scss";

function User () {
  const user = Core.use("auth.user.profile");
  const lang = Core.use("lang");
  const id = user.get("id");

  return (
    <section className="User">
      <Nest className="block wall" caption={lang.spell("map./user.caption.main")}>
        <Avatar src={user.get("avatar")}/>
        <div className="grid">
          <Pack className="account">
            {id ? <DBForm entity="kin_users" ui="userform" id={id} /> : null}
          </Pack>
        </div>
      </Nest>
    </section>
  );
}

export default User;
