import React from 'react'

import iconsList from "./icons";
import imagesList from "./images";
import langLibs from "./langs";

//import { Eshop } from "../Eshop/Mods";
import { TrayBar } from "../Core/Components";

import { DB } from "../DB/Mods";

import jet from "@randajan/react-jetpack";

//env 
require('dotenv').config();

const {REACT_APP_VERSION, REACT_APP_STAGE, REACT_APP_DEBUG, REACT_APP_API_URL, REACT_APP_GTAG} = process.env;

const config = process.config = jet.map.clone(window.__config); //external config;
const debug = jet.bol.to(REACT_APP_DEBUG);

//initialize ESHOP
export default {
    debug,
    trayBar:<TrayBar/>,
    crashMsg:"Neočekávaná chyba :'( Prosím kontaktujte naši podporu emailem "+config.email,
    version:debug ? "test" : REACT_APP_VERSION,
    analyticTag:REACT_APP_GTAG,
    cryptKey:navigator.userAgent,
    langDefault:jet.map.dig(config, "lang.default"),
    langFallback:jet.map.dig(config, "lang.fallback"),
    langList:jet.map.dig(config, "lang.list"),
    langLibs,
    sessionUrl:null,//debug ? null : "/static/php/session.php",
    apiUrl:REACT_APP_API_URL,
    authPath:"sauth",
    authProviders:["google"],
    screenSizes:{
        xsh:(w,h)=>h<400,
        sh:(w,h)=>h<600
    },
    flags:{
        screen:core=>jet.rele.flags(core.get("screen")).toString(),
        builded:core=>core.build.state === "result"
    },
    iconsList,
    imagesList,
    atBuild:core=>{

        //filter query
        core.fit("page", next=>{
            const v = next();
            delete v.search["fbclid"];
            return v;
        });

        //fit title
        core.fit("page", next=>{
            const v = next();
            v.title = (debug ? REACT_APP_STAGE.toUpperCase()+"! " : "");
            const lang = core.lang;
            if (lang) {
                const route = v.search.contact ? "contact" : v.pathname;
                v.title += lang.spell(["map", route, "at"]) || lang.spell("map.null.at") || config.name;
            }
            return v;
        });

        //lang change title
        core.eye("lang.now", _=>core.set("page.title"));

    },
    onBuild:async core=>{
        const { page, auth } = core;

        //auto authenticate user
        if (page.is("pathname", "/user") && page.get("search.code")) {
            await auth.setPassport(page.pull("search.code"));
        }

        

        await core.modMount("db", DB).build;

        await core.storeLocal("privacy").fill();

        await core.storeLocal("form.calc").version(REACT_APP_VERSION).fill();

        //await core.modMount("eshop", Eshop).build;

        core.storeApi("care.prices", "db/care_prices").fill();
        

    }
  }