import React, { useRef, useEffect } from 'react';

import jet, { useEngage } from "@randajan/react-jetpack";

import Core, { Modal, Ico, Link, Caption } from "@randajan/react-app-core";
import { Form, Button } from "@randajan/react-form";

import HttpError from "../HttpError/HttpError";
import Mist from "../Mist/Mist";
import buildField from "../Fields/Fields";

import DB from "../../Mods/DB";


import "./Form.scss";

function DBForm(props) {
  const id = props.id || "+";
  const { children, className, entity, ui, search, cache, result, onPost, labels, lookupUrl, modField, loadingWheel, caption, nocaption, createOnly } = props;
  let { output } = props;

  const ref = useRef();
  const pop = Modal.usePop();
  const lang = Core.use("lang");

  //const [store, setStore] = Core.useKey(["db.store.form", entity, ui, id]);

  const { getting, meta, info, data } = DB.useGet(entity, ui, id, {limit:1}, cache);
  const { posting, post } = DB.usePost(entity, ui, id, search, [
    post=>{
      if (post.error) {return pop.up(<HttpError error={post.error}/>); }
      if (!post.result) { return; }
      const op = _=>jet.fce.run(onPost, ref.current, post, pop);
      if (result) { pop.up({children:result, onDown:op}); } else { op(); }
    }
  ]);

  const { fields, division, plural, singular } = meta;
  const name = DB.useCaption(lang, division, plural, singular, ui)
  const rawput = jet.obj.tap(data);
  output = jet.obj.tap(output);

  const propsSelf = {
    ref, className,
    flags:{db:jet.map.it({entity, ui, id}, (v, k)=>k+":"+v)},
    //onOutput:form=>setStore(form.getOutput()),
    labels:alias=>jet.str.to(labels, alias) || lang.spell(["db.field", alias]) || jet.str.capitalize(alias)
  }

  return (
    <Form {...propsSelf}>
      <div className="header">
        <Caption className="caption">
          {nocaption ? null : (caption || name)}
          <span className="clue" title={id}>{data[info.clue]}</span>
        </Caption>
      </div>
      <div className="fields">
        {
          getting.pending ? null : Object.entries(jet.obj.tap(fields))
            .sort((f1, f2)=>(f1[1].index-f2[1].index) || (f1[1].id - f2[1].id))
            .map(([alias, field])=>buildField({meta, alias, id, rawput:rawput[alias], output:output[alias], editable:true, lookupUrl, modField}))
        }
      </div>
      <div className="notes">
        {children}
      </div>
      <div className="buttons">
        {createOnly ? null : <Button type="reject">{lang.spell("db.solid.reject")}</Button>}
        <Button type="submit" onSubmit={_=>post(ref.current.getOutput())}>{lang.spell(["db.solid", createOnly?"send":"save"])}</Button>
      </div>
      <Mist in={getting.pending || posting.pending}>
        {loadingWheel}
      </Mist>
    </Form>
  );
}

export default DBForm;
