import React, { useEffect } from 'react';
import { CSSTransition } from "react-transition-group";

import jet, { useSwipe, useShift } from "@randajan/react-jetpack";
import Core, { Ico, Link } from "@randajan/react-app-core";

import { LangMenu } from "../../Core/Components";

import Contact from "../Contact/Contact";

import "./Foot.scss";

const { tel } = process.config;

function Hotline() {
  return (
    <Link className="Hotline" to={"tel:"+jet.str.efface(tel, /[\s\n\r]/g)}>
      {tel}
    </Link>
  )
}


function Copyright() {
  const lang = Core.use("lang");
  const egg = jet.type.key.rnd(jet.arr.tap(lang.spell("eastereggs"))); //UJ
  return (
    <span className="copyright" title={egg}>
      {lang.spell("name") + " " + new Date().getFullYear()}
    </span>
  )
}

function Foot () {
  const [contact, setContact] = Core.useKey("page.search.contact");

  const [swipeRef] = useSwipe(({dir})=>setContact(dir==="up"?1:undefined), {up:contact?0:.3, down:contact?.3:0, initX:.5, initY:.5, appendState:true});

  return (
    <CSSTransition in={!!contact} timeout={1000} appear>
      <footer className={"Foot"}>
        <div className="view" ref={swipeRef}>
          <div className="fog" onClick={_=>setContact(undefined)}/>
          <nav className="wall">
            <div className="block">
              <LangMenu/>
              <div className="handle" onClick={_=>setContact(1)}>
                <Copyright />
              </div>
              <Hotline/>
            </div> 
          </nav>
          <Contact/>
        </div>
      </footer>
    </CSSTransition>
  );
}

export default Foot;