import React, { useState, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import jet from "@randajan/react-jetpack";

import { Ico } from "@randajan/react-app-core";


import "./Rating.scss";

function Rating() {
  const [ref, inView] = useInView({triggerOnce:true, threshold: .8});
  return (
    <div ref={ref} className={jet.str.to(["Rating", inView ? "inview" : ""], " ")}>
      <Ico src="itcan/diamond"/>
      <Ico src="itcan/diamond"/>
      <Ico src="itcan/diamond"/>
      <Ico src="itcan/diamond"/>
      <Ico src="itcan/diamond"/>
    </div>
  )
}


export default Rating