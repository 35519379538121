import React from 'react';

import Core, { Article, Nest, Pack } from "@randajan/react-app-core";

import "./Services.scss";

function Services () {
  const lang = Core.use("lang");

  return (
    <section className="Services">
      <Nest className="block wall" caption={lang.spell("map./services.caption.main")}>
        <Article className="services grid" src="md.services.grid"/>
        <Pack notag><Article className="brands" src="md.services.brands"/></Pack>
      </Nest>
    </section>
  );
}

export default Services;
