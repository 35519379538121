import jet from "@randajan/jetpack";

import { LangLib } from "@randajan/react-app-core";

const req = require.context('./', true, /\.(md|jsonc)$/);

const prefix = {
    md:"md"
}

const files = {};
const libs = {};

req.keys().map(file=>{
    const path = file.substr(2).split("/");
    const base = path.pop().split(".");
    const mime = base.pop();
    const lang = base.pop();
    const lib = path.concat(base).join(".");
    const id = jet.map.melt([mime, lib], ":");
    files[id] = files[id] || {};
    files[id][lang] = req(file);

    libs[id] = libs[id] || new LangLib(
        1,
        jet.map.melt([prefix[mime], lib], "."), 
        [],
        lang=>fetch(files[id][lang]).then(resp=>mime==="jsonc"?resp.json():resp.text())
    )

    libs[id].list.push(lang);
});

export default jet.obj.vals(libs);
