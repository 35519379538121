import React from 'react';
import "./UserMenu.scss";

import jet from "@randajan/react-jetpack";

import Core, { Link, Modal, Pack, Article } from "@randajan/react-app-core";

import { Menu, Button } from "@randajan/react-form";
import UserAvatar from "../UserAvatar/UserAvatar";

function LoginHandle({exe, msg, ok, children}) {
  const pop = Modal.usePop();

  return (
    <a className="LoginHandle" onClick={_=>pop.up(
      <Pack className="LoginBox" level={1}>
        {msg}
        <Button onSubmit={_=>{exe(); pop.down();}}>{ok}</Button>
      </Pack>
    )}>
      {children}
    </a>
  )
}


function UserMenu({ position }) {
  const lang = Core.use("lang");
  const auth = Core.use("auth");
  const isReal = auth.isFull("user.profile.id");
  const profile = jet.obj.tap(auth.get("user.profile"));

  const propsSelf = {
    className:"UserMenu",
    trigger:<UserAvatar {...profile} />,
    position,
    transition:{enter:200, exit:600}
  }

  return (
    <Menu {...propsSelf}>
      {isReal ? <Link to="/user">{lang.spell("map./user.to")}</Link> : null}
      <Link to="/user/privacy">{lang.spell("map./user/privacy.to")}</Link>
      <LoginHandle 
        exe={_=>isReal ? auth.logout() : auth.login("google")}
        msg={<Article src={["md.user", isReal ? "logout" : "login"]}/>}
        ok={lang.spell(["core.auth", isReal ? "logout" : "login"])}
        children={lang.spell(["core.auth", isReal ? "logout" : "login"])}
      />
    </Menu>
  );
};

export default UserMenu;
