import React from 'react';
import "./EshopProvider.scss";

function EshopProvider () {
  return (
    <section className="EshopProvider">
      <div className="block">
        <h1>EshopProvider</h1>
      </div>
    </section>
  );
}

export default EshopProvider;
