import React from 'react';

import jet from "@randajan/jetpack";

import Core, { Link, Ico, Img } from "@randajan/react-app-core";

import "./Contact.scss";

let { tel, email, locations, ic, bank, socials} = process.config;

locations = jet.arr.tap(locations).map(a=>{
  const children = `${a.street}, ${a.city} ${a.district}`;
  const href = a.url || "https://google.com/maps/search/" + encodeURI(children);
  return {className:"address", ico:"pin", href, target:"_blank", children}
});

const contacts = [
  //{className:"tel", ico:"whatsapp", href:"tel:"+tel.efface(" "), children:tel},
  {className:"email", ico:"email", href:"mailto:"+email, children:email},
  ...locations,
  {className:"ic", ico:"ic", href:"https://wwwinfo.mfcr.cz/cgi-bin/ares/darv_res.cgi?jazyk=cz&xml=1&ico="+ic, target:"_blank", children:ic},
  {className:"bankacc", ico:"cash", children:bank},
];



function AddressRow(props) {
  
  const lang = Core.use("lang");
  const {className, ico, href, children} = props;
  const label = lang.spell("db.field."+className);

  return (
    <div className={["AddressRow", className].join(" ")} title={label}>
      <div className="label">
        {ico ? <Ico src={ico} title={label}/> : label}
      </div>
      <div className="value textshadow">
        {href ? <Link {...props}/> : children}
      </div>
    </div>
  )
}

function Social(props) {
  return (
    <div className="Social">
      {
        jet.arr.tap(socials).map((s,k)=>
          <Link key={k} className={s.provider} to={s.url} target={"_blank"}>
            <Img src={"social/"+s.provider}/>
          </Link>
        )
      }
    </div>
  )
}

function Address() {
  const lang = Core.use("lang");
    
  return (
    <div className="Address">
      <h1>{lang.spell("map.contact.caption.main")}</h1>
      <div className="table">
        {contacts.map((prop,key)=><AddressRow key={key} {...prop}/>)}
      </div>
      <Social/>
    </div>
  )

}

function GoogleMap() {
  return (<div className="GoogleMap">
    <iframe
      src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.755170776882!2d14.480522499999994!3d50.072146599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9372b4565c11%3A0xac4f2ed081c64a0f!2s"+encodeURI(locations[0].children)+"!5e0!3m2!1scs!2scz!4v1585735917235!5m2!1scs!2scz"}
      frameBorder="0"
      allowFullScreen="" 
      aria-hidden="true" 
      tabIndex="0">
    </iframe>
  </div>)
}


function Contact() {

  return (
    <div className="Contact wall">
      <div className="block">
        <GoogleMap />
        <Address/>
      </div>
    </div>
  );
}


export default Contact