import React, { useEffect } from 'react';

import Core, { Modal } from "@randajan/react-app-core";

function useHashPop(hash, children) {
  const phash = Core.use("page.hash");
  const pop = Modal.usePop({
    children,
    onUp:_=>phash.set("", hash),
    onDown:_=>{if (phash.get() === hash) { phash.set("", null); }}
  });

  useEffect(_=>{
    if (phash.get() === hash) { pop.up(); }
  })

  return pop;
}

export default useHashPop;
