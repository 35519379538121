import React from 'react';
import { CSSTransition } from "react-transition-group";

import Core, { Ico } from "@randajan/react-app-core";

import { Article } from "@randajan/react-app-core";

import "./Cookies.scss";

const islocal = process.env.REACT_APP_STAGE === "local";

function Note () {
  const [cookies, setCookies] = Core.useKey("privacy.cookies");

  return (
    <CSSTransition in={!cookies} timeout={600} unmountOnExit appear>
      <section className="CookieNote wall orange">
        <div className="block flex">
          <Article src="md.cookies.note"/>
          <Ico src="ok" onClick={_=>setCookies(true)}/>
        </div>
      </section>
    </CSSTransition>
  );
}

export default Note;
