import React from 'react';

import jet from "@randajan/jetpack";

import Core, { Modal, Ico } from "@randajan/react-app-core";

import ContactForm from "../ContactForm/ContactForm";

import "./ContactControl.scss";

function ContactControl(props) {
  const { type, onUp, onDown, className } = props;
  const pop = Modal.usePop({lock:true, onUp, onDown});

  const lang = Core.use("lang");
  
  return (
    <div className={jet.str.to(["ContactControl", className, type], " ")} onClick={_=>pop.up(<ContactForm className="wall" onSubmit={pop.down.bind(pop)} type={type}/>)}>
        <Ico src={type}/>
        <span>{lang.spell(["eshop", type, "edit"])}</span>
        <ContactForm type={type} lock/>
    </div>
  );
}

export default ContactControl;
